import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Natural Language Processing (“NLP”) is an integral component in creating a customer service chatbot that is effective at both understanding and satisfying users. A chatbot’s performance depends on its ability to match user inputs to the correct Intent with precision and deliver an appropriate response to satisfy the user. In other words, how well can the chatbot give customers the answers they are looking for?`}</p>
    <p>{`Like any machine learning algorithm, the NLP model has to be trained to hone its accuracy. It is important to provide it with as much quality training data as possible in order to give customers a delightful experience.`}</p>
    <p>{`Below are four options for optimizing training data.`}</p>
    <h1><img parentName="h1" {...{
        "src": "https://lh3.googleusercontent.com/r7o0EwFj5EWmjot0XHUWgnUED9wT9PVZ133HXb5LOU-WU4Rgzic3myLm0VpJ3AMGiuh9yNrzyswDezz5BsNtbaP5Sd92Vid2lphOEzYONvtpyfUdymBO5DQZ7wFXQ28bZKT2Ee-1",
        "alt": null
      }}></img>{` 1. Mine historical live agent logs`}</h1>
    <p>{`If your current customer support strategy includes live agents or Interactive Voice Response (IVR), you have a lot to get started with. By examining live agent logs and transcripts, you can get a clear idea of what users are asking for the most, and how they are asking their questions.`}</p>
    <p>{`Depending on how many historical transcripts you have, you might find this process bottlenecked by sheer volume. If that is the case, consider using a service like `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/features/phrase-clustering/"
      }}>{`Dashbot’s Phrase Clustering, which groups semantically similar messages together, `}</a>{`to help identify common Intents.`}</p>
    <p>{`Phrase Clustering is helpful even if you do not plan on using an NLP engine. Phrase Clusters will point out the top groupings of messages, which can be used in decision tree, or regular expression, models as well.`}</p>
    <h1><img parentName="h1" {...{
        "src": "https://lh4.googleusercontent.com/u_p_wxs2f6JiIkBWrNkUzogjWVh5vybvNvm7W1u8s8JkYF4yQlZ8kNv5RxCfQBUFUwb_f00DWBPZq_QXBYKjKTlzvhALnuzApgv7NsEypohxSrp3F6cDpa2moowkz2AxLnLzML7P",
        "alt": null
      }}></img>{` 2. Leverage crowdsourcing`}</h1>
    <p>{`If you lack historical transcripts to train your NLP engine, you have the option of collecting your own training data through crowdsourcing. With crowdsourcing, you can generate potential training phrases through the collected wisdom of a community. For example, if you were building a chatbot for an insurance company, you could ask the community what questions they typically ask their insurance company, or perhaps more specifically, how do they ask for the status of a claim.`}</p>
    <p>{`There are a variety of third party solutions to help with crowdsourcing training phrases. One option is to make user of a service like `}<a parentName="p" {...{
        "href": "https://www.mturk.com/"
      }}>{`Amazon’s Mechanical Turk`}</a>{` to perform your own crowdsourcing campaign. Alternatively, there are third parties that specialize in specific use cases.`}</p>
    <h1><img parentName="h1" {...{
        "src": "https://lh4.googleusercontent.com/v4Zmjk9bz0pK4VcoNvaqyKbSb0mhseTOs3-REEUtLeKb_MAmppVCb074fdI9T-brFPmf-V4cq87gZNvnJ3MWaCxPVDsqJoXImawNtR0HgrkkoEYD6rlX8bXGD_ePYiBUVD_YLTqI",
        "alt": null
      }}></img>{` 3. Start with a pre-made training set`}</h1>
    <p>{`If starting from scratch is a little daunting for you, depending on your use case, there may be a pre-made training set you can use. . For example, both Google through its `}<a parentName="p" {...{
        "href": "https://developers.google.com/actions/verticals/overview"
      }}>{`Vertical Solutions`}</a>{` and Amazon through its `}<a parentName="p" {...{
        "href": "https://blueprints.amazon.com"
      }}>{`Blueprints`}</a>{` offer predefined training sets for a variety of use cases. Simply select which vertical you are building for and let Google or Amazon do the heavy lifting.`}</p>
    <p>{`Google also has a premade `}<a parentName="p" {...{
        "href": "https://cloud.google.com/dialogflow/docs/reference/follow-up-intent-expressions"
      }}>{`list of common Intents`}</a>{` that are universally used no matter what industry you are in. Although these are general Intents that apply to any use case, they are still vital for effective communication between your users and chatbot. A conversational interface that fails to understand and correctly respond to common phrases like “yes” or “no” will not go far.`}</p>
    <h1><img parentName="h1" {...{
        "src": "https://lh4.googleusercontent.com/Ha1CgHiyBQT0Hn5KLEASIVIx0SGpUSRvSVlLtCm6PKPDM-sG5kHt4mIR6QO2ugR53LaHu29N-kUt8afaKIBJZpEQ4WECcoEhGKMZVuDhWnPRzjrNpzvU3C9eRNs0Sz-RttWC1iXr",
        "alt": null
      }}></img>{` 4. Monitor and iterate based on the analytics`}</h1>
    <p>{`Crowdsourcing, pre-made data sets, and historical live agent logs are great places to get started, but as your customers interact with your chatbot, you will have access to much more accurate data: what your customers are actually saying.`}</p>
    <p>{`Optimization is an extremely vital step in chatbot development, as you should be updating the NLP model to meet more of your customers’ needs. There is no better data to do that with, than what your chatbot gathers while interacting with your users. By utilizing the wealth of information available in transcripts and interactions, you can increase response effectiveness and decrease escalations.`}</p>
    <p>{`Taking full advantage of all the data at your fingertips requires a high level of visibility into your chatbot conversations. With Dashbot, not only will you be able to see every message that comes in, but you will also have access to reports and tools that point to the health of your chatbot and areas where improvements are needed – for example identifying `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour/conversational-analytics/#phrase-clusters"
      }}>{`mishandled`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour/conversational-analytics/#unhandled"
      }}>{`unhandled`}</a>{` Intents to improve the NLP. This information can be leveraged as training data to optimize your current customer support chatbot experience.`}</p>
    <p>{`Whichever option, or options, you select to get started, it is important to monitor the performance of your chatbot with an analytics platform and incorporate the insights to improve the user experience.`}</p>
    <h1>{`About Dashbot`}</h1>
    <p><em parentName="p">{`Dashbot is an analytics platform for conversational interfaces that enables enterprises to increase satisfaction, engagement, and conversions through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support DialogFlow, Alexa, Google Assistant, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      